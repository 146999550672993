import styled from "styled-components";
import { COLORS } from "../../theme/colors";

export const PackageBox = styled.div`
  padding: 10px 10px;
  .editSvg{
    height:2rem;
    cursor:pointer;
    &:hover {
  path[stroke] {
    stroke: ${COLORS.darkBlue};
     }
   }
}
`;

export const SearchBox = styled.div`
padding: 5px 10px;
display:flex;
gap:20px;
align-items:center;
justify-content:end;
`
export const FilterOptionsBox = styled.div`
display: flex;
gap:50px;
`

export const Ticket = styled.div`
  display: flex;
  padding: 10px 25px;
  /* max-width:350px; */
  ${(props) => `background:${COLORS[props.color]};`};
  border-radius: 10px;
  .left-ticket {
    padding-right: 10px;
  }
  p {
    margin-bottom: 2px;
    font-size:1rem;
    font-weight:500;
  }
  .subData{
    font-size:12px
  }
`;