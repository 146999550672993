import React, { useEffect, useState } from "react";
import {
  PackageBox,
  SearchBox,
  FilterOptionsBox,
  Ticket,
} from "./package.styled";
import {
  Card,
  Table,
  ContentHeader,
  Dropdown,
  Input,
  Loader,
  Button,
} from "../../theme/components";
import AddEditUser from "./addEditUser";
import { Convert } from "../../helpers/convert.helper";
import BarChart from "../../components/barchart/index.js";
import TotalItemsImg from "../../assets/icons/totalItems.svg";
import axios from "axios";
import moment from "moment";
import { ReactComponent as EditImg } from "../../assets/icons/book.svg";
export default function Dashboard() {
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setuserData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFullString, setFullScreen] = useState(false);
  const [isFullEmail, setIsFullEmail] = useState(false);
  const [usersDataByFilter, setUsersDataByFilter] = useState("All");
  const [totalUsersData, setTotalUserData] = useState({});
  // const [chartData, setChartData] = useState([]);
  const [chartDataLoading, setChartDataLoading] = useState(true);
  const [data,setData] = useState({});

  useEffect(() => {
    getUsersData();
    // getPieChartData();
  }, [currentPage, sortBy]);

  // function getPieChartData() {
  //   setChartDataLoading(true);
  //   axios
  //     .get(`https://beta.qwikskills.com/nasscom/details`)
  //     .then((res) => {
  //       setChartData(res.data);
  //       setChartDataLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setChartDataLoading(false);
  //     });
  // }
  // useEffect(() => {
  //   totalCount();
  // }, []);

  // function totalCount() {
  //   axios
  //     .get(`https://beta.qwikskills.com/nasscom/total-count`)
  //     .then((res) => {
  //       // console.log(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  function getUsersData() {
    setIsLoading(true);
    const reqData = Convert.ObjectToQuery({ page: currentPage, range: sortBy });
    axios
      .get(`https://beta.qwikskills.com/nasscom/findAll?${reqData}`)
      .then((res) => {
        setuserData(res?.data);
        setTotalUserData(res?.data?.totalItems);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        alert("plz refresh the page");
      });
  }

  const sortByDate = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "1h",
      label: "Last Hour",
    },
    {
      value: "today",
      label: "Today",
    },
    {
      value: "24h",
      label: "Last 24 Hours",
    },
    {
      value: "yesterday",
      label: "Yesterday",
    },
    {
      value: "threeDaysAgo",
      label: "Last 3 Days",
    },
    {
      value: "7d",
      label: "Last 7 Days",
    },
  ];

  // const userSortOptions = [
  //   {
  //     label: "Total Count",
  //     value: "totalCount"
  //   },
  //   {
  //     label: "Last Hour",
  //     value: "countLastHour"
  //   },
  //   {
  //     label: "Last 24 Hours",
  //     value: "countLast24Hours"
  //   },
  //   {
  //     label: "  Today  ",
  //     value: "countToday"
  //   },
  //   {
  //     label: "Yesterday",
  //     value: "countYesterday"
  //   },
  //   {
  //     label: "Last 3 Days",
  //     value: "countLast3Days"
  //   },
  //   {
  //     label: "Last Week",
  //     value: "countLastWeek"
  //   }
  // ]
  const columns = [
    // {
    //   Key: 'sno',
    //   title: 'S no',
    //   render: (index) => {
    //     console.log(index)
    //     return (
    //       <div>
    //         1
    //       </div>
    //     )
    //   }
    // },
    {
      key: "firstName",
      title: "Name",
      render: (data, type, row) => {
        if (type?.first_name) {
          return type?.first_name + " " + type?.last_name;
        } else {
          return "N/A";
        }
      },
    },
    {
      key: "email",
      title: "Email",
      render: (data, type, row) => {
        if (type?.email_id) {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setIsFullEmail(!isFullEmail)}
            >
              {isFullEmail || type?.email_id.length < 20
                ? type?.email_id
                : type?.email_id.substring(0, 20) + "..."}
            </span>
          );
        } else {
          return "N/A";
        }
      },
    },
    {
      key: "Dob",
      title: "Date Of Birth",
      render: (data, type, row) => {
        if (type?.dob) {
          // return moment(type?.createdAt).format('DD-MM-YYYY, HH:mm A')
          return type?.dob;
        } else {
          return "N/A";
        }
      },
    },
    {
      key: "currentEducation",
      title: "Current Education",
      render: (data, type, row) => {
        if (type?.current_education) {
          return type?.current_education;
        } else {
          return "N/A";
        }
      },
    },
    {
      key: "State",
      title: "State",
      render: (data, type, row) => {
        if (type?.state) {
          return type?.state;
        } else {
          return "N/A";
        }
      },
    },
    {
      key: "District",
      title: "District",
      render: (data, type, row) => {
        if (type?.district) {
          return type?.district;
        } else {
          return "N/A";
        }
      },
    },
    {
      key: "College",
      title: "College",
      render: (data, type, row) => {
        if (type?.college_name) {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setFullScreen(!isFullString)}
            >
              {isFullString || type?.college_name.length < 10
                ? type?.college_name
                : type?.college_name.substring(0, 10) + "..."}
            </span>
          );
        } else {
          return "N/A";
        }
      },
    },
    // {
    //   key: "Income",
    //   title: "Income",
    //   render: (data, type, row) => {
    //     if (type?.annual_income) {
    //       // return moment(type?.createdAt).format('DD-MM-YYYY, HH:mm A')
    //       return type?.annual_income;
    //     } else {
    //       return "N/A";
    //     }
    //   },
    // },
    {
      key: "actions",
      title: "Actions",
      render: (data, type, row) => {
        return <EditImg className="editSvg" onClick={() => {
          setShow(true);
          setData(type);
        }} />
      }
    },
  ];

  const totalUsersDatas = totalUsersData + 32759;
  // console.log(totalUsersData);
  const handleOnClose = () => {
    setShow(false);
  };
  return (
    <PackageBox>
      <Loader isLoading={isLoading} />
      <ContentHeader
        title="Training Dashboard"
        btnText=" "
      // btnAction={() => {
      //   getUsersData();
      //   // totalCount();
      // }}
      />
      <br />
      <Card>
        <FilterOptionsBox>
          <Ticket color="lightBlue1">
            <div>
              <p style={{ fontSize: "1rem", fontWeight: "700" }}>
                Total User Registrations
              </p>
              <p>
                {/* {totalUsersData?.totalCount}*/}
                {userData?.totalItems}
              </p>
              {/* {console.log(typeof totalUsersData?.totalCount)} */}
            </div>
          </Ticket>
          {/* <Ticket color="lightBlue1">
            <div>
              <p style={{ fontSize: "1rem", fontWeight: "700" }}>
                Total Users In Training
              </p>
              <p>16028</p>
            </div>
          </Ticket> */}
          {/* <Dropdown
            style={{ minWidth: "150px" }}
            label="Sort By Date"
            options={sortByDate}
            onChange={(selectedValue) => {
              setSortBy(selectedValue[0]?.value);
              setUsersDataByFilter(selectedValue[0]?.label);
            }}
          /> */}
          <Ticket color="lightBlue1">
            <div className="d-flex flex-column w-100">
              <p style={{ fontSize: "1rem", fontWeight: "700" }}>
                {" "}
                Total Users Completed
              </p>
              <div className="d-flex justify-content-between">
                {/* <p>{userData?.["totalItems"]}</p> */}
                <p>{userData?.totalItems}</p>

                {/* <Dropdown options={userSortOptions}
                  style={{ backgroundColor: "white", minWidth: '150px' }}
                  placeholder="Total Count"
                  onChange={(selectedValue) => {
                    setUsersDataByPaymentFilter(selectedValue[0]?.value);
                  }}
                /> */}
              </div>
            </div>
          </Ticket>
          {/* <Ticket color="lightBlue1">
            <div>
              <p style={{ fontSize: "1rem", fontWeight: "700" }}>
                Module 1 Progress
              </p>
              <p>8%</p>
            </div>
          </Ticket>
          <Ticket color="lightBlue1">
            <div>
              <p style={{ fontSize: "1rem", fontWeight: "700" }}>
                Module 2 Progress
              </p>
              <p>22%</p>
            </div>
          </Ticket>{" "}
          <Ticket color="lightBlue1">
            <div>
              <p style={{ fontSize: "1rem", fontWeight: "700" }}>
                Module 3 Progress
              </p>
              <p>70%</p>
            </div>
          </Ticket> */}
          {/* <Dropdown label='Sort By Status' />
          <Dropdown label="Sort By Vendor" />
          <Dropdown label='Sort By JobRole' /> */}
        </FilterOptionsBox>
      </Card>
      <br />
      {/* <BarChart isLoading={chartDataLoading} /> */}
      <Card>
        {/* <SearchBox>
          <span>Search</span>
          <Input onChange={(e) => {
            setSearch(e.target.value)
            console.log(e.target.value)
          }} />
        </SearchBox> */}

        <Table
          columns={columns}
          data={userData?.items}
          totalItems={userData?.totalItems}
          totalPages={userData?.totalPages}
          pageNo={userData?.currentPage}
          onPageChange={(currentPage) => {
            setCurrentPage(currentPage);
          }}
        />
        {show && (
          <AddEditUser
            show={show}
            onClose={handleOnClose}
            data={data}
          />
        )}
      </Card>
    </PackageBox>
  );
}
