import styled from "styled-components";
import { COLORS } from "../../colors";

export const Button = styled.div`
background:${COLORS.darkBlue};
font-size:1.5rem;
color:${COLORS.white};
font-weight:500;
display:flex;
justify-content:center;
align-items:center;
width:25px;
height:25px;
border-radius:5px;
margin-top:5px;
margin-right:2px;
cursor:pointer;
&:hover {
    background:${COLORS.blue};
}
`