import React from "react";
import { ThemeProvider } from "styled-components";
import { darkTheme, GlobalStyles, lightTheme } from "../theme";
import { useRoutes } from "react-router-dom";
import routes from "../routes";

function App(props) {
  const routing = useRoutes(routes());
  return (
    <div>
      <ThemeProvider
        theme={props?.theme?.mode === "light" ? lightTheme : darkTheme}
      >
        <GlobalStyles />
        {routing}
      </ThemeProvider>
    </div>
  );
}


export default App;
