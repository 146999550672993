import { Navigate, Outlet } from "react-router-dom";
import Dashboard from "../views/dashboard";
import NotFound from "../views/notFound";
const routes = () => [
  {
    path: "/",
    element: <Dashboard />
  },
  { path: "/404", element: <NotFound /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
