import React, { useState, useEffect } from "react";
import { Modal, Input, Button, Dropdown, TextArea, Switch, Checkbox } from "../../../theme/components";

const formData = {
  firstName: "",
  lastName: "",
  email: "",
  gender:"",
  dob:"",
  mobile:"",
  collegeName:"",
  annualIncome:"",
  currentEducation:"",
  graduationYear:'',
  parentName:'',
  pinCode:'',
  state:'',
  district:'',
  demography:'',
  occupation:'',
  referredBy:'',
  socialCategory:'',
  specialCategory:'',
  universityEnrolmentNo:''
};

function AddEditUser(props) {
  const [form, setForm] = useState(formData);
  const { show = false, data = null, onClose = () => { } } = props;

  useEffect(() => {
    console.log(data);
    if (data) {
      setForm({
        ...form,
        'firstName': data?.first_name,
        'lastName': data?.last_name,
        'email': data?.email_id,
        'gender': data?.gender,
        'dob': data?.dob,
        'mobile': data?.mobile,
        'collegeName': data?.college_name,
        'annualIncome': data?.annual_income,
        'currentEducation': data?.current_education,
        'graduationYear': data?.graduation_year,
        'parentName': data?.parent_name,
        'pinCode': data?.pin_code,
        'state': data?.state,
        'district': data?.district,
        'demography': data?.demography,
        'occupation': data?.occupation,
        'referredBy': data?.referred_by,
        'socialCategory': data?.social_category,
        'specialCategory': data?.special_category,
        'universityEnrolmentNo': data?.university_enrolment_no,
      })
    }
  }, [])

  return (
    <div>
      <Modal
        show={show}
        onClose={onClose}
      >
        <Input className="mb-2"
          label="First Name"
          name="firstName"
          placeholder="First name"
          maxWidth="100%"
          disabled={true}
          value={form.firstName}
        />
        <Input className="mb-2"
          label="Last Name"
          name="lastName"
          placeholder="Last name"
          maxWidth="100%"
          disabled={true}
          value={form.lastName}
        />
        <Input className="mb-2"
          label="Parent Name"
          name="parentName"
          placeholder="parent_name"
          maxWidth="100%"
          disabled={true}
          value={form.parentName}
        />
        <Input className="mb-2"
          label="Email"
          name="email"
          placeholder="Email"
          maxWidth="100%"
          disabled={true}
          value={form.email}
        />
        <Input className="mb-2"
          label="Gender"
          name="gender"
          placeholder="gender"
          maxWidth="100%"
          disabled={true}
          value={form.gender}
        />
        <Input className="mb-2"
          label="Date Of Birth"
          name="dob"
          placeholder="dob"
          maxWidth="100%"
          disabled={true}
          value={form.dob}
        />
        <Input className="mb-2"
          label="Mobile"
          name="mobile"
          placeholder="mobile"
          maxWidth="100%"
          disabled={true}
          value={form.mobile}
        />
        <Input className="mb-2"
          label="College Name"
          name="collegeName"
          placeholder="college name"
          maxWidth="100%"
          disabled={true}
          value={form.collegeName}
        />
        <Input className="mb-2"
          label="Annual Income"
          name="annualIncome"
          placeholder="Annual Income"
          maxWidth="100%"
          disabled={true}
          value={form.annualIncome}
        />
        <Input className="mb-2"
          label="Current Education"
          name="currentEducation"
          placeholder="Current Education"
          maxWidth="100%"
          disabled={true}
          value={form.currentEducation}
        />
        <Input className="mb-2"
          label="Graduation Year"
          name="graduation_year"
          placeholder="graduation_year"
          maxWidth="100%"
          disabled={true}
          value={form.graduationYear}
        />
        <Input className="mb-2"
          label="Pin Code"
          name="pinCode"
          placeholder="Pin Code"
          maxWidth="100%"
          disabled={true}
          value={form.pinCode}
        />
        <Input className="mb-2"
          label="State"
          name="state"
          placeholder="State"
          maxWidth="100%"
          disabled={true}
          value={form.state}
        />
        <Input className="mb-2"
          label="District"
          name="district"
          placeholder="District"
          maxWidth="100%"
          disabled={true}
          value={form.district}
        />
        <Input className="mb-2"
          label="Demography"
          name="demography"
          placeholder="Demography"
          maxWidth="100%"
          disabled={true}
          value={form.demography}
        />
        <Input className="mb-2"
          label="Occupation"
          name="occupation"
          placeholder="Occupation"
          maxWidth="100%"
          disabled={true}
          value={form.occupation}
        />
        {/* <Input className="mb-2"
          label="Referred By"
          name="referred_by"
          placeholder="Referred By"
          maxWidth="100%"
          disabled={true}
          value={form.referredBy}
        /> */}
        <Input className="mb-2"
          label="Social Category"
          name="social_category"
          placeholder="social_category"
          maxWidth="100%"
          disabled={true}
          value={form.socialCategory}
        />
        <Input className="mb-2"
          label="Special Category"
          name="special_category"
          placeholder="social_category"
          maxWidth="100%"
          disabled={true}
          value={form.specialCategory}
        />
        <Input className="mb-2"
          label="University Enrolment_no"
          name="university_enrolment_no"
          placeholder="university_enrolment_no"
          maxWidth="100%"
          disabled={true}
          value={form.universityEnrolmentNo}
        />
        <Button className="mt-2" small outline secondary onClick={()=>onClose() }>Close</Button>
      </Modal>
    </div>
  );
}

export default AddEditUser;
