import styled from "styled-components";
import { COLORS } from "../../colors";

export const ContentHeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 68%;
  .logos{
    display: flex;
    gap:10px;
  }
`;
