import React from "react";
import { Button, Card } from "../button";
import { ContentHeaderBox } from "./contentHeaderStyled";
import logo from "../../../assets/images/logoq.png";
import logonfsp from "../../../assets/images//joneProfilepic.png";
import { ReactComponent as QuestionImage } from "../../../assets/images/refresh.svg";
export const ContentHeader = (props) => {
  const {
    title = "",
    btnText = "",
    btnAction = () => {},
    subTitle = "",
  } = props || {};
  return (
    <ContentHeaderBox>
      <div>
        {title && <h4 style={{ paddingLeft: "10px" }}>{title}</h4>}
        {subTitle && <h6>{subTitle}</h6>}
      </div>
      <div className="logos">
        <img
          src={
            "https://qs-training-resources.s3.amazonaws.com/nasscom/srds-transparent.png"
          }
          style={{ width: "200px" }}
        />
        <img
          src={logo}
          style={{ width: "250px", marginTop: "10px", height: "48px" }}
        />
        {/* <h1 style={{ color: "#5B43EA" }}>QWIKSKILLS</h1> */}
      </div>
      {/* {btnText && (
        <Button outline onClick={btnAction} transparent small >
            <QuestionImage style={{width:'15px' , height:'15px', marginRight:'5px'}}/>
          {btnText}
        </Button>
      )} */}
    </ContentHeaderBox>
  );
};
